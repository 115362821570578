document.addEventListener('DOMContentLoaded', () => {
    // Add handlers where necessary
    const selector = 'button[data-onclick-navigation],button[data-onclick-print]';
    const buttonsWithClickEvents = document.querySelectorAll(selector);
    buttonsWithClickEvents.forEach((btn) => {
        const attrs = btn.getAttributeNames();
        if (attrs.includes('data-onclick-navigation')) {
            const location = btn.getAttribute('data-onclick-navigation');
            btn.addEventListener('click', () => {
                window.location = `${util.urlBase()}${location}`;
            });
        }
        if (attrs.includes('data-onclick-print')) {
            btn.addEventListener('click', () => window.print());
        }
    });

    const formsWithActions = document.querySelectorAll('form[data-add-onsubmit]');
    formsWithActions.forEach((form) => {
        form.addEventListener('submit', (e) => window._submit(e));
    });

    // Remove any overlays that were hidden whilst page was loading
    const container  = document.querySelector('#container');
    const bodyFooter = document.querySelector('#body-footer');
    const overlay    = document.querySelector('#loading-overlay');
    if (container) container.classList.remove('invisible');
    if (bodyFooter) bodyFooter.classList.remove('invisible');
    // Polyfill support for IE 9+
    if (overlay) overlay.parentNode.removeChild(overlay);

    // Show any notification banners that must appear on page load
    const notificationsEl = document.querySelector('script[data-notification]');
    if (notificationsEl) {
        const notifications = JSON.parse(notificationsEl.textContent || notificationsEl.innerText);
        notifications.forEach(({ title, message, status }) => {
            window.notification.notify(title, message, status);
        });
    }
});
